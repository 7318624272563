import Image from "next/legacy/image";
import Highlights from "@/components/shared/Highlights";
import { Grid } from "@hiltermann/components/ui";
import { parseText } from "@/utilities/htmlParser";
import { GetDynamicContentItemProps } from "./DynamicContentBlock/types";

type BigUspBlockProps = GetDynamicContentItemProps<"BigUspBlock">;

const BigUspBlock: React.FC<BigUspBlockProps> = ({
  heading1,
  heading2,
  body,
  image,
  mobile_image,
  highlights,
}) => {
  const defaultImage = image?.[0]?.default;
  const mobileImage = mobile_image?.url;
  return (
    <section data-block="BigUspBlock" className="desktop-s:mt-32 mt-14">
      <Grid className="overflow-hidden">
        <p className="text-p text-secondary-aubergine desktop-s:col-start-2 desktop-s:mb-6 desktop-s:text-intro col-span-full row-start-1 mb-4 font-serif tracking-wide">
          {heading1}
        </p>
        <h2 className="text-h2-sm desktop-s:col-span-4 desktop-s:col-start-2 desktop-s:mb-20 desktop-s:text-h2 col-span-full row-start-2 mb-6 font-bold tracking-wide">
          {heading2}
        </h2>
        {body && (
          <div className="text-p-sm desktop-s:col-span-5 desktop-s:col-start-7 desktop-s:row-start-2 desktop-s:mb-20 desktop-s:text-p col-span-full row-start-3 mb-6 font-serif tracking-wide">
            {parseText(body)}
          </div>
        )}
        {defaultImage && (
          <>
            <div className="hidden desktop-s:block col-span-10 col-start-2 row-start-3 mb-20 h-[31.75rem] relative">
              <Image
                src={defaultImage}
                alt={image?.[0]?.description || "promo image"}
                layout="fill"
                objectFit="cover"
              />
            </div>
            <div className=" hidden desktop-s:block row-start-3 mt-16 relative col-span-full row-span-2">
              <div className="bg-primary-black absolute -inset-x-10 -top-0 bottom-0 -z-10 opacity-[0.02]" />
            </div>
          </>
        )}
        {(defaultImage || mobileImage) && (
          <>
            <div className="relative col-span-full row-start-4 mb-10 h-[13.75rem] block desktop-s:hidden">
              <Image
                src={mobileImage || defaultImage || ""}
                alt={mobile_image?.description || "promo image"}
                layout="fill"
                objectFit="cover"
              />
            </div>
            <div className="block desktop-s:hidden relative col-span-full row-span-2 row-start-4 mt-10">
              <div className="bg-primary-black absolute -inset-x-10 -top-0 bottom-0 -z-10 opacity-[0.02]" />
            </div>
          </>
        )}

        {highlights && (
          <Highlights
            highlights={highlights}
            className="desktop-s:row-start-4 desktop-s:pb-20 row-start-5 pb-10"
          />
        )}
      </Grid>
    </section>
  );
};
export default BigUspBlock;
