import React from "react";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import { useButtonTarget } from "@/hooks/useButtonTarget";

import Container from "../shared/Container";
import BlogsEntryBlockCards from "./BlogsEntryBlock/BlogsEntryBlockCards";
import { mapBlogsEntryBlockPropsToCards } from "./BlogsEntryBlock/mapBlogsEntryBlockPropsToCards";
import { Button } from "@hiltermann/components/form";

export type BlogsEntryBlockProps = GetDynamicContentItemProps<"BlogsEntryBlock">;

const BlogsEntryBlock: React.FC<BlogsEntryBlockProps> = ({
  title,
  blogs,
  cta_text,
  button_text,
  button_target,
  className,
}) => {
  const { type, href, onClick, isValid } = useButtonTarget(button_target?.[0]);
  if (!blogs) return null;
  return (
    <section data-block="BlogsEntryBlock" className={`relative pb-2 ${className ?? ""}`}>
      <Container className="self-center">
        <h2 className="text-h2-sm tablet:text-h3 text-center font-sans font-bold tracking-wide">
          {title}
        </h2>
      </Container>
      <BlogsEntryBlockCards data={mapBlogsEntryBlockPropsToCards(blogs)} />
      {isValid && button_text && (
        <Container>
          <div className="tablet:flex-row tablet:justify-center tablet:gap-14 flex flex-col items-center gap-6">
            <p className="text-p-sm tablet:text-intro font-serif tracking-wide">{cta_text}</p>
            <Button type={type} href={href} onClick={onClick} variant="secondary">
              {button_text}
            </Button>
          </div>
        </Container>
      )}
    </section>
  );
};

export default BlogsEntryBlock;
