import React from "react";
import Container from "@/components/shared/Container";
import TextLink from "@/components/shared/TextLink";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import { InformationTabsBlockProps } from "../InformationTabsBlock";
import InformationTabsAccordion from "./InformationTabsBlockMobile/InformationTabsAccordion";

const InformationTabsBlockMobile: React.FC<InformationTabsBlockProps> = ({
  title,
  description,
  tabs,
  button_text,
  button_target,
}) => {
  const extraButton = useButtonTarget(button_target[0]);

  return (
    <Container>
      {title && <h2 className="text-h2-sm mb-6 text-center font-bold tracking-wide">{title}</h2>}
      {description && (
        <p className="text-p-sm mb-10 w-full text-center font-serif tracking-wide">{description}</p>
      )}
      <InformationTabsAccordion tabs={tabs} />
      {button_text && extraButton.isValid && (
        <TextLink
          variant="secondary"
          type={extraButton.type}
          href={extraButton.href}
          onClick={extraButton.onClick}
          className="mt-8 inline-flex"
        >
          {button_text}
        </TextLink>
      )}
    </Container>
  );
};

export default InformationTabsBlockMobile;
