import Link from "next/link";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import { parseQuoteDark, parseQuote, parseText } from "@/utilities/htmlParser";
import QuoteImage from "@/components/blocks/CustomerExperienceEntryBlock/QuoteImage";
import { Grid } from "@hiltermann/components/ui";
import CustomerStoriesButton from "./CustomerExperienceEntryBlock/CustomerStoriesButton";

export type CustomerExperienceEntryBlockProps =
  GetDynamicContentItemProps<"CustomerExperienceEntryBlock">;

const CustomerExperienceEntryBlock: React.FC<CustomerExperienceEntryBlockProps> = ({
  darkthemeenabled,
  title,
  body,
  ctabuttontext,
  ctabuttontarget,
  customquote,
  customer_story,
  className,
}) => {
  const { _slug, caption, image } = customer_story?.[0] ?? {};
  const quote = customquote ?? customer_story?.[0]?.quote ?? "";
  const customerImage = image?.[0];
  const colors = darkthemeenabled ? "gradient-2 text-white" : "bg-grey-light text-black";
  const panelColor = darkthemeenabled ? "bg-primary-black" : "bg-white";

  const replaceWithCurlyMarks = (text: string) => text.replace(/"([^"]*)"/g, "“$1”");

  return (
    <div className={`desktop-s:py-20 relative w-full py-10 ${colors} ${className ?? ""}`}>
      <Grid>
        <div className="desktop-s:col-span-5 desktop-s:col-start-2 desktop-s:mt-20 col-span-4 flex flex-col tablet:col-span-12">
          <h2 className="text-h3-sm desktop-s:text-h4 font-sans font-bold tracking-wide">
            {title}
          </h2>
          <div className="text-p-sm desktop-s:text-p mt-6 font-serif tracking-wide">
            {parseText(body ?? "")}
          </div>
          <CustomerStoriesButton
            text={ctabuttontext}
            target={ctabuttontarget}
            darkThemeEnabled={darkthemeenabled}
            className="desktop-s:block hidden"
          />
        </div>
        <Link
          href={_slug ?? "/klantverhalen"}
          className={`desktop-s:col-start-8 desktop-s:row-start-1 desktop-s:mt-0 group col-span-4 tablet:col-span-12 desktop-s:col-span-4 row-span-2 mt-8 overflow-hidden ${panelColor}`}
        >
          {customerImage?.default && (
            <QuoteImage
              image={customerImage.default}
              alt={customerImage.description ?? "customer image"}
            />
          )}
          {caption && quote && (
            <div className="desktop-s:mt-10 desktop-s:px-12 desktop-s:pb-14 mt-8 px-6 pb-10">
              <div className="text-p-xs desktop-s:text-p font-serif tracking-wide">{caption}</div>
              <div className="text-h4-sm desktop-s:mt-5 desktop-s:text-h3-sm mt-3.5 font-bold tracking-wide">
                {darkthemeenabled
                  ? parseQuoteDark(replaceWithCurlyMarks(quote))
                  : parseQuote(replaceWithCurlyMarks(quote))}
              </div>
            </div>
          )}
        </Link>
        <CustomerStoriesButton
          text={ctabuttontext}
          target={ctabuttontarget}
          darkThemeEnabled={darkthemeenabled}
          className="desktop-s:hidden block"
        />
      </Grid>
    </div>
  );
};

export default CustomerExperienceEntryBlock;
