import clsx from "clsx";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import { Button } from "@hiltermann/components/form";
import { GetDynamicContentItemProps } from "./DynamicContentBlock/types";

type Props = GetDynamicContentItemProps<"CtaAboveHeader">;

const CtaAboveHeader: React.FC<Props> = ({
  text,
  button_text,
  button_target,
  className,
  button_text_2,
  button_target_2,
}) => {
  const button_1 = useButtonTarget(button_target?.[0]);
  const button_2 = useButtonTarget(button_target_2?.[0]);

  // the component needs either text or valid button, otherwise it should not be visible:
  if (!text && (!button_1.isValid || !button_text) && (!button_2.isValid || !button_text_2))
    return null;

  const hasButton1 = button_text && button_1.isValid;
  const hasButton2 = button_text_2 && button_2.isValid;

  return (
    <section
      data-block="CtaAboveHeader"
      className={clsx("tablet:h-[74px] relative flex justify-center", className)}
    >
      <div className="tablet:my-0 tablet:flex-row tablet:pt-0 col-span-full row-start-1 flex flex-col items-center justify-between pt-6">
        {text && (
          <span
            className={`text-p tablet:mr-14 tablet:text-intro tablet:mb-0 mx-4 mb-4 text-center font-serif tracking-wide text-black`}
          >
            {text}
          </span>
        )}
        {hasButton1 && (
          <Button
            variant="tertiary"
            size="small"
            type={button_1.type}
            href={button_1.href}
            onClick={button_1.onClick}
            className={`tablet:my-0 tablet:w-auto w-full ${hasButton2 ? "mb-4" : "mb-7"}`}
          >
            {button_text}
          </Button>
        )}
        {hasButton2 && (
          <Button
            variant="secondary"
            size="small"
            type={button_2.type}
            href={button_2.href}
            onClick={button_2.onClick}
            className="tablet:my-0 tablet:ml-3 tablet:w-auto mb-7 w-full"
          >
            {button_text_2}
          </Button>
        )}
      </div>
    </section>
  );
};

export default CtaAboveHeader;
