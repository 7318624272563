import React from "react";
import { useDialogForms } from "@/components/Layout/useDialogForms";

import { Button } from "@hiltermann/components/form";
import { unpackButtonTarget } from "@/hooks/useButtonTarget";
import { Path } from "@/utilities/types";
import { InformationTabsBlockProps } from "../../InformationTabsBlock";
import { Accordion } from "@hiltermann/components/ui";

interface InformationTabsAccordionProps {
  tabs: Path<InformationTabsBlockProps, ["tabs"]>;
}

const InformationTabsAccordion: React.FC<InformationTabsAccordionProps> = ({ tabs }) => {
  const dialogForms = useDialogForms();
  const accordionItems =
    tabs?.map(({ title, content, button_text, button_target }) => {
      const { type, href, onClick, isValid } = unpackButtonTarget(button_target[0], dialogForms);
      return {
        title,
        content: (
          <>
            {content && <p className="text-p-sm font-serif tracking-wide">{content}</p>}
            {button_text && isValid && (
              <Button
                type={type}
                variant="secondary"
                className="mt-6 font-sans"
                href={href}
                onClick={onClick}
              >
                {button_text}
              </Button>
            )}
          </>
        ),
      };
    }) ?? [];

  return <Accordion items={accordionItems} />;
};

export default InformationTabsAccordion;
