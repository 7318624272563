import Image from "next/legacy/image";
import React from "react";
import { Button } from "@hiltermann/components/form";
import { ButtonTarget, useButtonTarget } from "@/hooks/useButtonTarget";
import { Maybe } from "@/services/prepr/types_codegen";

interface CtaProps {
  image?: Maybe<{
    default?: Maybe<string>;
    description?: Maybe<string>;
  }>;
  text: string;
  buttonTarget?: ButtonTarget;
  buttonText: string;
  className?: string;
}

const Cta: React.FC<CtaProps> = ({ image, text, buttonTarget, buttonText, className }) => {
  const button = useButtonTarget(buttonTarget);
  return (
    <div
      className={`tablet:mx-auto tablet:flex-row col-span-full mx-0 flex flex-col items-center justify-between ${
        className ?? ""
      }`}
    >
      <div className="tablet:mb-0 mb-10 flex flex-1 items-center">
        {image?.default && (
          <div className="tablet:mr-6 tablet:size-20 relative mr-4 size-14 shrink-0">
            <Image
              src={image.default}
              layout="fill"
              className="rounded-full bg-white"
              alt={image.description || "employee image"}
            />
          </div>
        )}
        <p className="text-p tablet:mr-16 tablet:text-intro font-serif tracking-wide">{text}</p>
      </div>
      {button.isValid && buttonText && (
        <Button
          variant="secondary"
          type={button.type}
          href={button.href}
          onClick={button.onClick}
          className="tablet:w-auto w-full"
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default Cta;
