import React from "react";
import Image from "next/legacy/image";
import { Grid } from "@hiltermann/components/ui";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";

type OneImageBlockProps = GetDynamicContentItemProps<"OneImageBlock">;

const OneImageBlock: React.FC<OneImageBlockProps> = ({ image: img, description, className }) => {
  const image = img?.[0];

  if (!image) return null;
  return (
    <Grid dataBlock="OneImageBlock" className={`relative ${className ?? ""}`}>
      {image.desktop && (
        <div className="tablet:block tablet:aspect-[1408/508] relative col-span-full hidden">
          <Image
            src={image.desktop}
            alt={image.description ?? ""}
            layout="fill"
            objectFit="cover"
          />
        </div>
      )}
      {image.mobile && (
        <div className="tablet:hidden relative col-span-full block aspect-[328/220]">
          <Image src={image.mobile} layout="fill" alt={image.description ?? ""} objectFit="cover" />
        </div>
      )}
      {description && (
        <p className="text-p-xs text-secondary-burgundy tablet:mt-6 tablet:text-p col-span-full mt-4 font-serif tracking-wide">
          {description}
        </p>
      )}
    </Grid>
  );
};

export default OneImageBlock;
