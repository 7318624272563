import { GetDynamicPageQuery } from "@/services/prepr/queries/getDynamicPage.generated";
import { GetFromUnion, Path } from "@/utilities/types";

export type DynamicContentItem = Path<GetDynamicPageQuery, ["DynamicPage", "content", number]>;
export type DynamicContentItemTypename = Path<DynamicContentItem, ["__typename"]>;
export type GetDynamicContentItemProps<T> = Omit<
  GetFromUnion<DynamicContentItem, T>,
  "__typename"
> & {
  className?: string;
};
export const allowedDynamicContentItemTypenames = [
  "PageHeader",
  "PageHeaderThree",
  "PageHeaderTwo",
  "PageHeaderWithIconButtons",
  "BigUspBlock",
  "ParagraphBlock",
  "HeadingBlock",
  "QuoteBlock",
  "OneImageBlock",
  "TwoImagesBlock",
  "ThreeImagesBlock",
  "ButtonBlock",
  "HighlightsBlock",
  "PageLinksBlock",
  "SubjectOverviewBlock",
  "CustomerExperienceEntryBlock",
  "MoreCustomerExperiencesEntryBlock",
  "FaqBlock",
  "ContactPersonBlock",
  "CallToActionBlock",
  "CallToActionBlock2",
  "TeamBlock",
  "BlogsEntryBlock",
  "SpotlightBlogBlock",
  "InformationTabsBlock",
  "SubHeaderLinksBlock",
  "CustomerExperienceCarouselBlock",
  "EmployeeExperienceCarouselBlock",
  "OpenCookieNotificationLink",
  "WhitepaperRequestBlock",
  "StepByStepBlock",
  "CtaAboveHeader",
  "IconsBlock",
  "ContactServiceBlock",
  "FaqOverviewBlock",
  "FaqSingleSubjectBlock",
  "SubHeaderLinkCardsBlock",
  "ScriptBlock",
  "HelloRiderBlock",
  "VideoBlock",
] as const;
export type FilteredDynamicContentItemTypename =
  (typeof allowedDynamicContentItemTypenames)[number];
export type FilteredDynamicContentItem = Extract<
  DynamicContentItem,
  { __typename: FilteredDynamicContentItemTypename }
>;
