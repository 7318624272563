import React from "react";
import { Grid } from "@hiltermann/components/ui";
import { parseText } from "@/utilities/htmlParser";
import { Path } from "@/utilities/types";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import SubjectDesktop from "./SubjectOverviewBlock/SubjectDesktop";
import SubjectMobile from "./SubjectOverviewBlock/SubjectMobile";

type SubjectOverviewBlockProps = GetDynamicContentItemProps<"SubjectOverviewBlock">;
export type SubjectOverviewTarget = Path<
  SubjectOverviewBlockProps,
  ["cards", number, "target", number]
>;

const SubjectOverviewBlock: React.FC<SubjectOverviewBlockProps> = ({
  title,
  subtitle,
  body,
  cards,
  className,
}) => {
  return (
    <section data-block="SubjectOverviewBlock" className={`relative ${className ?? ""}`}>
      <Grid>
        {title && (
          <p className="text-p text-secondary-burgundy tablet:col-span-9 tablet:col-start-2 tablet:text-intro col-span-full row-start-1 font-serif tracking-wide">
            {title}
          </p>
        )}
        {subtitle && (
          <h2 className="text-h2-sm tablet:col-span-4 tablet:col-start-2 tablet:mt-6 tablet:text-h3 col-span-full row-start-2 mt-4 font-sans font-bold tracking-wide">
            {subtitle}
          </h2>
        )}
        {body && (
          <div className="text-p-sm tablet:col-span-5 tablet:col-start-7 tablet:row-start-2 tablet:mt-20 tablet:text-p col-span-full row-start-3 mt-6 font-serif tracking-wide">
            {parseText(body)}
          </div>
        )}
      </Grid>
      {!!cards?.length && (
        <>
          <div className="tablet:block relative mt-20 hidden">
            <div className="bg-primary-black/[0.02] absolute inset-x-0 inset-y-14" />
            <Grid>
              {cards.map((cardProps, index) => {
                const { title, body, target, image } = cardProps ?? {};
                if (!title || !body) return null;
                return (
                  <SubjectDesktop
                    key={index}
                    index={index}
                    title={title}
                    body={body}
                    target={target?.[0]}
                    image={image?.[0]}
                  />
                );
              })}
            </Grid>
          </div>
          <div className="tablet:hidden mt-6 block">
            {cards.map((cardProps, index) => {
              const { title, body, target, image } = cardProps ?? {};
              if (!title || !body) return null;
              return (
                <SubjectMobile
                  key={index}
                  title={title}
                  body={body}
                  target={target?.[0]}
                  image={image?.[0]}
                />
              );
            })}
          </div>
        </>
      )}
    </section>
  );
};

export default SubjectOverviewBlock;
