import React from "react";
import { parseDate } from "@/utilities/parseDate";

interface BlogCaptionProps {
  type?: string | null;
  publishOn?: string | null;
}

const BlogCaption: React.FC<BlogCaptionProps> = ({ type, publishOn }) => {
  const parsedPublishOn = publishOn && parseDate(publishOn);
  return (
    <div className="flex flex-col desktop-s:flex-row desktop-s:flex-wrap desktop-s:items-center gap-x-6">
      {type && <p className="text-menu-sm font-sans font-bold">{type}</p>}
      {parsedPublishOn && (
        <p className="text-p text-grey-asphalt font-serif tracking-wide">{parsedPublishOn}</p>
      )}
    </div>
  );
};

export default BlogCaption;
