import React from "react";
import { Button } from "@hiltermann/components/form";
import ImageWithFallback from "@/components/shared/ImageWithFallback";
import { Path } from "@/utilities/types";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import { GetDynamicContentItemProps } from "../DynamicContentBlock/types";

type PageHeaderProps = GetDynamicContentItemProps<"PageHeader">;
export type ActionCardProps = Omit<
  Path<PageHeaderProps, ["action_cards", number]>,
  "__typename"
> & {
  className?: string;
  __typename?: "ActionCard" | "PageHeaderActionCard" | undefined; // make type compatible with nested action cards in the PageHeader and backwards compatible with the current actioncards model used in the homepage
};

const ActionCard: React.FC<ActionCardProps> = ({
  title,
  body,
  button_text,
  button_target,
  image,
  className,
}) => {
  const { type, href, onClick, isValid } = useButtonTarget(button_target?.[0]);
  return (
    <div
      className={`
        transition-max-w bg-primary-black tablet:max-w-[568px] tablet:first:mr-8 desktop-s:hover:max-w-[688px] group
        relative mb-4
        flex
        w-full
        duration-300
        ease-in-out
        ${className ?? ""}`}
    >
      <div className="flex flex-col ">
        <div className="tablet:max-w-[400px] desktop-s:py-16 desktop-s:pl-16 px-6 py-10">
          <h3 className="text-h4-sm text-primary-mint tablet:mb-4 tablet:text-h3-sm mb-2 font-bold tracking-wide">
            {title}
          </h3>
          <div className="text-p tablet:mb-10 tablet:text-intro mb-6 shrink font-serif tracking-wide text-white">
            {body}
          </div>
          <div className="shrink">
            {isValid && button_text && (
              <Button variant="primary" type={type} href={href} onClick={onClick}>
                {button_text}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="desktop-s:transition-opacitry desktop-s:group-hover:opacity-100 pointer-events-none absolute right-8 h-full min-w-[250px] opacity-0 duration-300 ease-in-out">
        {image?.[0]?.default && (
          <ImageWithFallback
            src={image?.[0]?.default}
            objectPosition="bottom"
            layout="fill"
            objectFit="contain"
            alt={image?.[0]?.description || ""}
          />
        )}
      </div>
    </div>
  );
};

export default ActionCard;
