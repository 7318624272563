import { FilteredDynamicContentItemTypename } from "./types";

export const DEFAULT_MARGIN_CLASS = "mt-6";
export const MARGIN_CLASSES: Record<FilteredDynamicContentItemTypename, string> = {
  PageHeader: "mt-0",
  PageHeaderThree: "mt-0",
  PageHeaderTwo: "mt-0",
  PageHeaderWithIconButtons: "mt-0",
  ParagraphBlock: "mt-6",
  HeadingBlock: "mt-12",
  BigUspBlock: "mt-8 tablet:mt-20",
  QuoteBlock: "mt-8 tablet:mt-10",
  OneImageBlock: "mt-8 tablet:mt-20",
  TwoImagesBlock: "mt-8 tablet:mt-20",
  ThreeImagesBlock: "mt-8 tablet:mt-20",
  ButtonBlock: "mt-6 tablet:mt-12",
  HighlightsBlock: "mt-12 tablet:mt-32",
  PageLinksBlock: "mt-12 tablet:mt-32",
  SubjectOverviewBlock: "mt-12 tablet:mt-32",
  CustomerExperienceEntryBlock: "mt-12 tablet:mt-32",
  MoreCustomerExperiencesEntryBlock: "mt-12 tablet:mt-32",
  FaqBlock: "mt-12 tablet:mt-32",
  ContactPersonBlock: "mt-12 tablet:mt-32",
  ContactServiceBlock: "mt-12 tablet:mt-32",
  WhitepaperRequestBlock: "mt-12 tablet:mt-32",
  CallToActionBlock: "mt-8 tablet:mt-28",
  CallToActionBlock2: "mt-8 tablet:mt-20",
  TeamBlock: "mt-12 tablet:mt-32",
  BlogsEntryBlock: "mt-12 tablet:mt-32",
  SpotlightBlogBlock: "mt-12 tablet:mt-20",
  InformationTabsBlock: "mt-12 tablet:mt-20",
  SubHeaderLinksBlock: "mt-0 tablet:-mt-32",
  CustomerExperienceCarouselBlock: "mt-12 tablet:mt-32",
  EmployeeExperienceCarouselBlock: "mt-12 tablet:mt-32",
  OpenCookieNotificationLink: "mt-8",
  StepByStepBlock: "mt-12 tablet:mt-32",
  CtaAboveHeader: "mt-0",
  IconsBlock: "mt-0",
  FaqOverviewBlock: "",
  FaqSingleSubjectBlock: "mt-12 tablet:mt-32",
  SubHeaderLinkCardsBlock: "mt-0 tablet:-mt-20 desktop-s:-mt-48",
  ScriptBlock: "",
  HelloRiderBlock: "",
  VideoBlock: "mt-8 tablet:mt-35",
};

const shouldUseProperMarginClass = (
  dynamicContentItem: FilteredDynamicContentItemTypename
): boolean => {
  switch (dynamicContentItem) {
    case "ParagraphBlock":
      return true;
    default:
      return false;
  }
};

export const getMarginClass = (
  currentTypename?: FilteredDynamicContentItemTypename,
  prevTypename?: FilteredDynamicContentItemTypename
) => {
  const prevMarginClassExists = !!(prevTypename && MARGIN_CLASSES[prevTypename]);
  const currentMarginClassExists = !!(currentTypename && MARGIN_CLASSES[currentTypename]);
  if (!prevMarginClassExists && !currentMarginClassExists) return DEFAULT_MARGIN_CLASS;
  if (prevMarginClassExists && !currentMarginClassExists) return MARGIN_CLASSES[prevTypename];
  if (!prevMarginClassExists && currentMarginClassExists) return MARGIN_CLASSES[currentTypename];
  if (prevMarginClassExists && currentMarginClassExists) {
    if (shouldUseProperMarginClass(currentTypename)) {
      return MARGIN_CLASSES[currentTypename];
    }
    const currentTypeMargin = parseInt(
      (MARGIN_CLASSES[currentTypename] ?? "").replace(/mt-|tablet:mt-/, "").split(" ")[0]
    );
    const prevTypeMargin = parseInt(
      (MARGIN_CLASSES[prevTypename] ?? "").replace(/mt-|tablet:mt-/, "").split(" ")[0]
    );
    return prevTypeMargin > currentTypeMargin
      ? MARGIN_CLASSES[prevTypename]
      : MARGIN_CLASSES[currentTypename];
  }
};
