import clsx from "clsx";
import { PageLinksBlockProps } from "@/components/blocks/PageLinksBlock";
import PageCard from "@/components/blocks/PageLinksBlock/PageCard";
import PageCardWithImage from "@/components/blocks/PageLinksBlock/PageCardWithImage";

interface PageCardLinksProps {
  cards: PageLinksBlockProps["cards"];
  className?: string;
}
export const PageCardLinks: React.FC<PageCardLinksProps> = ({ cards, className }) => {
  return !!cards?.length ? (
    <div
      className={clsx(
        "desktop-xl:col-span-9 desktop-xl:col-start-2 desktop-s:row-start-5 desktop-s:grid desktop-s:gap-8 desktop-s:grid-cols-3 relative z-10 col-span-full row-start-4 flex flex-col gap-4 desktop-s:px-4",
        className
      )}
    >
      {cards.map((card, i) => {
        if (!card || !card.target || !card.target?.[0] || !card.title) return null;
        const image = card.image?.[0];
        if (image) {
          return (
            <PageCardWithImage
              key={i}
              title={card.title}
              subtitle={card.subtitle}
              image={image}
              target={card.target?.[0]}
            />
          );
        }
        return (
          <PageCard
            key={i}
            title={card.title}
            subtitle={card.subtitle}
            target={card.target?.[0]}
            dark={card.dark || undefined}
          />
        );
      })}
    </div>
  ) : (
    <></>
  );
};
