import Image from "next/legacy/image";
import { Grid } from "@hiltermann/components/ui";
import { parseText } from "@/utilities/htmlParser";
import { Button } from "@hiltermann/components/form";
import TextLink from "@/components/shared/TextLink";
import { useDialogForms } from "@/components/Layout/useDialogForms";
import { GetDynamicContentItemProps } from "../DynamicContentBlock/types";

export type WhitepaperRequestBlockProps = GetDynamicContentItemProps<"WhitepaperRequestBlock">;

export const WhitepaperRequestBlock: React.FC<WhitepaperRequestBlockProps> = ({
  titleoverwrite,
  bodyoverwrite,
  buttontext,
  whitepaper,
  personimage,
  className,
}) => {
  const { openWhitepaperDialog } = useDialogForms();
  return (
    <section data-block="WhitepaperRequestBlock" className={className}>
      <Grid className="relative z-0 h-full">
        <div className="tablet:mt-36 tablet:mb-32 relative col-span-9 my-10">
          <p className="text-p text-secondary-burgundy tablet:mb-6 tablet:text-intro mb-3 font-serif tracking-wide">
            Whitepaper
          </p>
          <h2 className="text-h1-sm tablet:mb-10 tablet:text-h4 mb-6 font-sans font-bold tracking-wide">
            {titleoverwrite || whitepaper?.[0].title}
          </h2>
          <div className="text-p tablet:mb-10 tablet:pr-20 mb-6 font-serif tracking-wide">
            {parseText(bodyoverwrite || whitepaper?.[0].bodytext || "")}
          </div>
          <div className="tablet:flex">
            <Button
              variant="tertiary"
              className="tablet:mr-12 tablet:mb-0 mb-8"
              data-testid="open-whitepaper-dialog"
              onClick={openWhitepaperDialog(
                whitepaper?.[0]?._slug || "",
                whitepaper?.[0]?.title?.toString() ?? "",
                whitepaper?.[0]?.bodytext ?? "",
                whitepaper?.[0]?.whitepaperfile?.[0]?.url ?? ""
              )}
            >
              {buttontext || "vraag whitepaper aan"}
            </Button>
            <div className="flex">
              <TextLink variant="secondary" href="/whitepapers">
                Bekijk alle whitepapers
              </TextLink>
            </div>
          </div>
        </div>
        {personimage?.[0]?.desktop && (
          <div className="gradient-2 tablet:block relative -z-10 col-span-12 col-start-11 mr-[-calc(120vh/2)] hidden">
            <div className="absolute bottom-0 left-0 -mr-24 aspect-[125/200] h-[90%] max-h-[1080px] -translate-x-1/2">
              <Image
                src={personimage?.[0]?.desktop}
                alt={personimage?.[0]?.description || "header image"}
                layout="fill"
                objectFit="cover"
                priority={true}
              />
            </div>
          </div>
        )}
      </Grid>
      {personimage?.[0]?.mobile && (
        <div className="tablet:hidden relative -mt-32 block aspect-[360/220] h-full min-h-56 pt-[32%]">
          <div className="gradient-2 h-full pl-5">
            <div className="absolute bottom-0 left-20 size-full">
              <Image
                src={personimage?.[0]?.mobile}
                alt={personimage?.[0]?.description || "header image"}
                layout="fill"
                objectFit="contain"
                priority={true}
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
