import React from "react";
import clsx from "clsx";
import { Grid } from "@hiltermann/components/ui";
import { PageCardLinks } from "../../shared/PageCardLinks/PageCardLinks";
import { GetDynamicContentItemProps } from "../DynamicContentBlock/types";

export type SubHeaderLinkCardsBlockProps = GetDynamicContentItemProps<"SubHeaderLinkCardsBlock">;

export const SubHeaderLinkCardsBlock: React.FC<SubHeaderLinkCardsBlockProps> = ({
  cards,
  className,
}) => {
  if (!cards) return null;
  return (
    <Grid
      dataBlock="SubHeaderLinkCardsBlock"
      containerClass={clsx("tablet:pb-20 relative overflow-hidden pb-10", className)}
      className="shadow-card bg-white py-8"
    >
      <PageCardLinks cards={cards} className="w-full" />
    </Grid>
  );
};
