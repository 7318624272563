import Image from "next/legacy/image";
import React, { Fragment } from "react";
import { Maybe } from "@/services/prepr/types_codegen";
import { parseText } from "@/utilities/htmlParser";

interface HighlightsProps {
  highlights: Maybe<{
    title?: Maybe<string>;
    description?: Maybe<string>;
    icon?: Maybe<Array<Maybe<{ url?: Maybe<string>; description?: Maybe<string> }>>>;
  }>[];
  className?: string;
}

const Highlights: React.FC<HighlightsProps> = ({ highlights, className }) => {
  return (
    <div
      className={`tablet:grid tablet:gap-x-16 desktop-s:col-span-10 desktop-s:col-start-2 desktop-s:gap-x-32 col-span-full flex flex-col justify-items-center ${
        className ?? ""
      }`}
      style={{ gridTemplateColumns: `repeat(${highlights.length}, minmax(0, 1fr))` }}
    >
      {highlights.map((highlight, index) => {
        if (!highlight) return null;
        const { title, description, icon } = highlight;
        const { url: iconUrl, description: iconDescription } = icon?.[0] ?? {};
        return (
          <Fragment key={index}>
            {iconUrl && (
              <div className="tablet:row-start-1 tablet:mt-0 tablet:size-12 relative mt-10 size-8 self-center first:mt-0">
                <Image src={iconUrl} alt={iconDescription ?? "icon"} layout="fill" />
              </div>
            )}
            {title && (
              <h3 className="text-forms text-secondary-burgundy tablet:row-start-2 tablet:mt-10 tablet:text-h4-sm mt-6 text-center font-sans font-bold tracking-wide">
                {title}
              </h3>
            )}
            {description && (
              <div className="text-p-sm tablet:row-start-3 tablet:mt-3 tablet:text-p mt-6 text-center font-serif tracking-wide">
                {parseText(description)}
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default Highlights;
