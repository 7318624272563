import type { FaqAccordionItem } from "@/services/prepr/queries/getFaqSubjects";
import { parseText } from "@/utilities/htmlParser";
import { Button } from "@hiltermann/components/form";
import { useDialogForms } from "@/components/Layout/useDialogForms";
import { unpackButtonTarget } from "@/hooks/useButtonTarget";
import { Accordion } from "@hiltermann/components/ui";
import { useState } from "react";

interface Props {
  items?: FaqAccordionItem[] | null;
  className?: string;
}

const FaqAccordion: React.FC<Props> = ({ items, className }) => {
  const dialogForms = useDialogForms();
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  if (!items) return null;

  const accordionItems = items?.map(({ question, answer, button_target, button_text }) => {
    const { type, href, onClick, isValid } = unpackButtonTarget(button_target?.[0], dialogForms);

    return {
      title: question,
      content: (
        <>
          {answer && parseText(answer)}
          {button_text && isValid && (
            <Button
              variant="tertiary"
              size="small"
              type={type}
              href={href}
              onClick={onClick}
              className="tablet:mt-8 mt-6"
            >
              {button_text}
            </Button>
          )}
        </>
      ),
    };
  });

  const limit = 5;

  return (
    <>
      <Accordion
        className={className}
        items={accordionItems.slice(0, showAll ? undefined : limit)}
      />

      {accordionItems.length > limit && (
        <div className="tablet:pt-12 pt-8">
          <Button onClick={toggleShowAll} type="button" variant="secondary">
            {showAll ? "Toon minder - " : "Toon meer +"}
          </Button>
        </div>
      )}
    </>
  );
};

export default FaqAccordion;
