import React from "react";
import { Grid } from "@hiltermann/components/ui";
import { parseText } from "@/utilities/htmlParser";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";

type ParagraphBlockProps = GetDynamicContentItemProps<"ParagraphBlock">;

const ParagraphBlock: React.FC<ParagraphBlockProps> = ({ html_text: htmlText, className }) => {
  if (!htmlText) return null;
  return (
    <Grid dataBlock="ParagraphBlock" className={`relative ${className ?? ""}`}>
      <div className="tablet:col-span-8 tablet:col-start-3 col-span-full text-black">
        <div className="text-p-sm tablet:text-p font-serif tracking-wide">
          {parseText(htmlText)}
        </div>
      </div>
    </Grid>
  );
};

export default ParagraphBlock;
