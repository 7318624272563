import React from "react";
import { Button } from "@hiltermann/components/form";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import { Path } from "@/utilities/types";
import { CustomerExperienceEntryBlockProps } from "../CustomerExperienceEntryBlock";

interface CustomerStoriesButtonProps {
  text?: string | null;
  target?: Path<CustomerExperienceEntryBlockProps, ["ctabuttontarget"]>;
  darkThemeEnabled?: boolean | null;
  className?: string;
}

const CustomerStoriesButton: React.FC<CustomerStoriesButtonProps> = ({
  text,
  target,
  darkThemeEnabled,
  className,
}) => {
  const { type, href, onClick, isValid } = useButtonTarget(target?.[0]);

  if (!isValid || !text) return null;
  return (
    <div
      className={`desktop-s:col-span-5 desktop-s:col-start-2 desktop-s:mt-12 col-span-4 mt-6 ${
        className ?? ""
      }`}
    >
      <Button
        type={type}
        href={href}
        onClick={onClick}
        variant={darkThemeEnabled ? "tertiary" : "secondary"}
      >
        {text}
      </Button>
    </div>
  );
};

export default CustomerStoriesButton;
