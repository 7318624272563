import React from "react";
import { Grid } from "@hiltermann/components/ui";
import { GetDynamicContentItemProps } from "./DynamicContentBlock/types";
import SubHeaderLink from "./SubHeaderLinksBlock/SubHeaderLink";

export type SubHeaderLinksBlockProps = GetDynamicContentItemProps<"SubHeaderLinksBlock">;

const SubHeaderLinksBlock: React.FC<SubHeaderLinksBlockProps> = ({ heading, links, className }) => {
  if (!links) return null;
  return (
    <section data-block="SubHeaderLinksBlock" className={className}>
      <Grid className="bg-primary-black relative z-10">
        <div className="tablet:px-12 desktop-s:py-14 desktop-s:px-20 col-span-full py-12">
          {heading && (
            <p className="text-forms text-secondary-grapefruit tablet:text-h3-sm font-sans font-bold tracking-wide">
              {heading}
            </p>
          )}
          <ul className="desktop-s:mt-11 desktop-s:flex-row desktop-s:gap-20 mt-6 flex flex-col gap-4">
            {links.map((link, index) => (
              <SubHeaderLink key={index} {...link} />
            ))}
          </ul>
        </div>
      </Grid>
    </section>
  );
};

export default SubHeaderLinksBlock;
