import { Grid } from "@hiltermann/components/ui";
import clsx from "clsx";
import { GetDynamicContentItemProps } from "../DynamicContentBlock/types";

export type VideoBlockProps = GetDynamicContentItemProps<"VideoBlock">;

export const VideoBlock: React.FC<VideoBlockProps> = ({ video_id, title, className }) => {
  if (!video_id) return null;
  return (
    <Grid className={className}>
      <section className={clsx("col-span-full tablet:col-span-10 tablet:col-start-2", className)}>
        <h2 className="text-h4-sm tablet:text-h3-sm font-sans font-bold mb-4 tracking-wide">
          {title}
        </h2>
        {/* Padding top 56.25% is a specific value for the responsiveness of the embedded video mentioned in several articles it represents the aspect ratio of the video, which is usually 16/9 on YouTube. */}
        <div className="overflow-hidden relative" style={{ paddingTop: "56.25%" }}>
          <iframe
            width="1166"
            height="708"
            src={`https://www.youtube.com/embed/${video_id}`}
            title={title ?? "youtube video"}
            color="#0B1423"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="absolute top-0 left-0 size-full"
          ></iframe>
        </div>
      </section>
    </Grid>
  );
};
