import clsx from "clsx";
import CardIconButton from "@/components/shared/CardIconButton";
import { ButtonTarget } from "@/hooks/useButtonTarget";
import useHoverState from "@/hooks/useHoverState";
import { Maybe } from "@/services/prepr/types_codegen";
import PageCardWrapper from "./PageCard/PageCardWrapper";

interface Props {
  title: string;
  subtitle?: Maybe<string>;
  target: ButtonTarget;
  dark?: boolean;
}

const PageCard: React.FC<Props> = ({ title, subtitle, target, dark = false }) => {
  const [isHovered, setIsHovered] = useHoverState();

  return (
    <PageCardWrapper
      target={target}
      setIsHovered={setIsHovered}
      className={clsx(
        "shadow-card relative block cursor-pointer",
        dark ? "bg-primary-black text-white" : "bg-white"
      )}
    >
      <div className="desktop-s:p-8 bottom-0 z-20 w-full p-6 opacity-100">
        <div className="flex items-center justify-between gap-10 drop-shadow-sm">
          <div>
            <div className="text-button-sm desktop-s:text-forms font-bold">{title}</div>
            {subtitle && <div className="text-button-sm desktop-s:text-forms">{subtitle}</div>}
          </div>
          <CardIconButton isHovered={isHovered} whiteArrow={dark} />
        </div>
      </div>
    </PageCardWrapper>
  );
};

export default PageCard;
